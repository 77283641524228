export const osList = [
  {
    name: 'Windows 95',
    regex: /(Windows 95|Win95|Windows_95)/
  },
  {
    name: 'Windows ME',
    regex: /(Win 9x 4.90|Windows ME)/
  },
  {
    name: 'Windows 98',
    regex: /(Windows 98|Win98)/
  },
  {
    name: 'Windows 2000',
    regex: /(Windows NT 5.0|Windows 2000)/
  },
  {
    name: 'Windows XP',
    regex: /(Windows NT 5.1|Windows XP)/
  },
  {
    name: 'Windows Server 2003',
    regex: /Windows NT 5.2/
  },
  {
    name: 'Windows Vista',
    regex: /Windows NT 6.0/
  },
  {
    name: 'Windows 7',
    regex: /(Windows 7|Windows NT 6.1)/
  },
  {
    name: 'Windows 8.1',
    regex: /(Windows 8.1|Windows NT 6.3)/
  },
  {
    name: 'Windows 8',
    regex: /(Windows 8|Windows NT 6.2)/
  },
  {
    name: 'Windows NT 4.0',
    regex: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
  },
  {
    name: 'Windows ME',
    regex: /Windows ME/
  },
  {
    name: 'Android',
    regex: /Android/
  },
  {
    name: 'Open BSD',
    regex: /OpenBSD/
  },
  {
    name: 'Free BSD',
    regex: /FreeBSD/
  },
  {
    name: 'Sun OS',
    regex: /SunOS/
  },
  {
    name: 'Ubuntu',
    regex: /Ubuntu/
  },
  {
    name: 'Linux',
    regex: /(Linux|X11)/
  },
  {
    name: 'iOS',
    regex: /(iPhone|iPad|iPod)/
  },
  {
    name: 'Mac OS X',
    regex: /Mac OS X/
  },
  {
    name: 'Mac OS',
    regex: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/
  },
  {
    name: 'QNX',
    regex: /QNX/
  },
  {
    name: 'UNIX',
    regex: /UNIX/
  },
  {
    name: 'BeOS',
    regex: /BeOS/
  },
  {
    name: 'OS/2',
    regex: /OS\/2/
  },
  {
    name: 'Search Bot',
    regex: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
  }
];
