import { osList } from './data'

export default class browserInfo {
  giveMeAllYouGot() {
    return {
      screenSize: browserInfo.getScreenSize(),
      windowSize: browserInfo.getWindowSize(),
      cookiesEnabled: browserInfo.areCookiesEnabled(),
      language: browserInfo.getLanguage(),
      os: browserInfo.getOSInfo(),
      isMobile: browserInfo.isMobile(),
      device: browserInfo.getDeviceType(),
      browser: this.getBrowserInfo(),
      userString: window.navigator.userAgent,
      network: browserInfo.getNetwork()
    }
  }

  static getScreenSize() {
    return {
      width: window.screen.width,
      height: window.screen.height
    }
  }

  static getNetwork() {
    let nw = ''
    const connection =
      window.navigator.connection ||
      window.navigator.mozConnection ||
      window.navigator.webkitConnection

    if (connection) nw = connection.effectiveType
    return nw
  }

  static getWindowSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  static isMobile() {
    let check = false
    ;(function(a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      ) {
        check = true
      }
    })(window.navigator.userAgent || window.navigator.vendor || window.opera)
    return check
  }

  static getDeviceType = () => {
    const ua =
      window.navigator.userAgent || window.navigator.vendor || window.opera
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  static areCookiesEnabled() {
    let cookieEnabled = window.navigator.cookieEnabled
    if (typeof cookieEnabled === 'undefined') {
      document.cookie = 'test-cookie'
      return document.cookie.indexOf('test-cookie') !== -1
    }
    return cookieEnabled
  }

  static getLanguage() {
    return window.navigator.language || window.navigator.userLanguage
  }

  static getOSInfo() {
    const osName = browserInfo.getOSName()
    if (/Windows/.test(osName)) {
      return {
        name: 'Windows',
        version: /Windows (.*)/.exec(osName)[1] // todo
      }
    }
    return {
      name: osName,
      version: browserInfo.getOSVersion(osName)
    }
  }

  static getOSName() {
    const currentOs = osList.find(os =>
      os.regex.test(window.navigator.userAgent)
    )
    return currentOs ? currentOs.name : 'Unknown'
  }

  static getOSVersion(osName) {
    switch (osName) {
      case 'Mac OS X':
        return browserInfo.getMacOSVersion()
      case 'Android':
        return browserInfo.getAndroidOSVersion()
      case 'iOS':
        return browserInfo.getIOSVersion()
      case 'Ubuntu':
        return browserInfo.getUbuntuOSVersion()
      default:
        return 'Unknown'
    }
  }

  static getMacOSVersion() {
    const version = /Mac OS X (10[._\d]+)/.exec(window.navigator.userAgent)
    return version ? version[1].replace(/_/g, '.') : 'Unknown'
  }
  static getAndroidOSVersion() {
    const version = /Android ([._\d]+)/.exec(window.navigator.userAgent)
    return version ? version[1] : 'Unknown'
  }
  static getIOSVersion() {
    const version = /OS (\d+)_(\d+)_?(\d+)?/.exec(window.navigator.appVersion)
    return version ? `${version[1]}.${version[2]}` : 'Unknown'
  }
  static getUbuntuOSVersion() {
    const version = /Ubuntu\/([._\d]+)/.exec(window.navigator.userAgent)
    return version ? version[1] : 'Unknown'
  }

  getBrowserInfo = () => {
    const userAgent = window.navigator.userAgent
    let browser
    if (/Opera/.test(userAgent)) {
      browser = browserInfo.getOperaInfo()
    } else if (/MSIE/.test(userAgent)) {
      browser = browserInfo.getIEInfo()
    } else if (/Edge/.test(userAgent)) {
      browser = browserInfo.getEdgeInfo()
    } else if (/Chrome/.test(userAgent)) {
      browser = browserInfo.getChromeInfo()
    } else if (/CriOS/.test(userAgent)) {
      browser = browserInfo.getMobileChromeInfo()
    } else if (/Safari/.test(userAgent)) {
      browser = browserInfo.getSafariInfo()
    } else if (/Firefox/.test(userAgent)) {
      browser = browserInfo.getFirefoxInfo()
    } else if (/Trident\//.test(userAgent)) {
      browser = browserInfo.getNewerIEInfo()
    } else {
      browser = browserInfo.getUnknownBrowserInfo()
    }
    browser.version = browserInfo.trimVersion(browser.version)
    return browser
  }

  static getOperaInfo() {
    const hasVersion = window.navigator.userAgent.indexOf('Version') !== -1
    return {
      name: 'Opera',
      version: hasVersion
        ? browserInfo.getBrowserVersion('Version', 8)
        : browserInfo.getBrowserVersion('Opera', 6)
    }
  }
  static getIEInfo() {
    return {
      name: 'Microsoft Internet Explorer',
      version: browserInfo.getBrowserVersion('MSIE', 5)
    }
  }
  static getEdgeInfo() {
    return {
      name: 'Microsoft Edge',
      version: browserInfo.getBrowserVersion('Edge', 5)
    }
  }
  static getChromeInfo() {
    return {
      name: 'Chrome',
      version: browserInfo.getBrowserVersion('Chrome', 7)
    }
  }
  static getMobileChromeInfo() {
    return {
      name: 'Chrome',
      version: browserInfo.getBrowserVersion('CriOS', 6)
    }
  }
  static getSafariInfo() {
    const hasVersion = window.navigator.userAgent.indexOf('Version') !== -1
    return {
      name: 'Safari',
      version: hasVersion
        ? browserInfo.getBrowserVersion('Version', 8)
        : browserInfo.getBrowserVersion('Safari', 7)
    }
  }
  static getFirefoxInfo() {
    return {
      name: 'Firefox',
      version: browserInfo.getBrowserVersion('Firefox', 8)
    }
  }
  static getNewerIEInfo() {
    return {
      name: 'Microsoft Internet Explorer',
      version: window.navigator.userAgent.substring(
        window.navigator.userAgent.indexOf('rv:') + 3
      )
    }
  }
  static getUnknownBrowserInfo() {
    // TODO maybe there's a way
    return {
      name: undefined, // eslint-disable-line
      version: undefined // eslint-disable-line
    }
  }

  static getBrowserVersion(browser, offset) {
    return window.navigator.userAgent.substring(
      window.navigator.userAgent.indexOf(browser) + offset
    )
  }

  static trimVersion(version = '') {
    let index
    if ((index = version.indexOf(')')) !== -1) {
      // eslint-disable-line
      version = version.substring(0, index)
    }
    if (index !== -1) {
      version = version.substring(0, index)
    }
    if ((index = version.indexOf(' ')) !== -1) {
      // eslint-disable-line
      version = version.substring(0, index)
    }
    return version
  }
}
