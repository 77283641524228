import React from 'react'
import ContentLoader from 'react-content-loader'
import { useWindowSize } from '../utils/useWindowSize'
import { rain } from '../../shared/images'

const homeLoaderStyles = {
  container: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    maxWidth: '100%',
    width: 1280
  },
  featured: {
    margin: '120px 0 15px'
  },
  body: {
    display: 'flex',
    backgroundImage: `url(${rain})`,
    border: `6px solid #f5f5f5`,
    padding: 30,
    width: '100%',
    height: 280
  },
  cell: {
    padding: 6,
    flex: 1
  },
  topHeader: {
    padding: '0 24px',
    marginTop: 60,
    display: 'flex',
    justifyContent: 'space-between'
  }
}

const renderListing = () => {
  return (
    <div style={homeLoaderStyles.cell}>
      <ContentLoader height={'208'} width={'100%'}>
        <rect x="0" y="0" width="100%" height="70%" />
        <rect y="75%" x="12" width="53%" height="12" />
        <rect y="85%" x="12" width="75%" height="16" />
      </ContentLoader>
    </div>
  )
}

const HomeLoader = () => {
  const { width } = useWindowSize()
  return (
    <div style={homeLoaderStyles.container}>
      <div style={homeLoaderStyles.topHeader}>
        <ContentLoader height={'200'} width={'15%'}>
          <rect x="0" y="0" width="100%" height="80%" />
        </ContentLoader>
        <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
          <ContentLoader height={'200'} width={'50%'}>
            <rect x="12.5%" y="25%" width="75%" height="25%" />
            <rect x="0" y="75%" width="100%" height="25%" />
          </ContentLoader>
        </div>
        <ContentLoader height={'200'} width={'15%'}>
          <rect x="0" y="0" width="100%" height="80%" />
        </ContentLoader>
      </div>
      <div style={homeLoaderStyles.featured}>
        <div style={homeLoaderStyles.body}>
          {renderListing()}
          {width > 860 ? renderListing() : null}
          {width > 600 ? renderListing() : null}
          {width > 320 ? renderListing() : null}
        </div>
      </div>
    </div>
  )
}

export default HomeLoader
