import React, { lazy, Suspense } from 'react'
import { RoomiBlackLargeLogo } from '../../shared/icons'
import dadaLogo from '../../shared/icons/DadaRoom_Logo.png'
import { Grid, withStyles } from '@material-ui/core'
import styles from './initial-loader.styles'
import ListingDetailsLoading from '../../listings/details/components/listing-details-loading/listing-details-loading.component'
import { Route, Switch } from 'react-router-dom'
import UserProfileLoading from '../../users/profile/user-profile-loading/user-profile-loading.component'
import SearchLoader from './search-loader'
import HomeLoader from './home-loader'
import BrowsePeopleLoader from './browse-people-loader'
import { isDadaDomain } from '../../utilities/isDada'

const Component = lazy(() => import('../../app.component'))

const paths = [
  {
    path: '/',
    exact: true,
    component: HomeLoader
  },
  {
    path: '/rooms-for-rent',
    component: ListingDetailsLoading
  },
  {
    path: '/myListings',
    component: ListingDetailsLoading
  },
  {
    path: '/users',
    component: UserProfileLoading
  },
  {
    path: '/s',
    component: SearchLoader
  },
  {
    path: '/rooms',
    component: SearchLoader
  },
  {
    path: '/roommate',
    component: UserProfileLoading
  },
  {
    path: '/browse-people',
    component: BrowsePeopleLoader
  },
  {
    path: '/roommates',
    component: BrowsePeopleLoader
  }
]
const allPaths = paths.reduce((agg, value) => {
  agg.push({ ...value })
  agg.push({ ...value, path: '/es' + value.path })
  agg.push({ ...value, path: '/en' + value.path })
  return agg
}, [])

const Loader = ({ classes }) => {
  return (
    <Grid container className={classes.main} direction={'column'}>
      <div className={classes.header}>
        <RoomiBlackLargeLogo className={classes.banner}/>
      </div>
      <Switch>
        {allPaths.map((v) => {
          return <Route key={v.path} exact={v.exact} component={v.component} />
        })}
      </Switch>
    </Grid>
  )
}

const StyledLoader = withStyles(styles)(Loader)

const InitialLoader = () => {
  return (
    <Suspense fallback={<StyledLoader />}>
      <Component />
    </Suspense>
  )
}

export default InitialLoader
