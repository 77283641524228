const styles = theme => ({
  header: {
    minHeight: 52,
    height: 52,
    marginBottom: 2,
    padding: `0 ${theme.spacing.unit * 5}px`,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    boxShadow: `0 4px 2px -2px ${theme.palette.grey[300]}`,
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing.unit * 3}px`
    }
  },
  banner: {
    width: 110
  },
  main: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flexWrap: 'nowrap'
  }
})

export default styles
