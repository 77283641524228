import React, { PureComponent } from 'react';

import withBreakPoints from '../../../../shared/components/with-breakpoints/with-breakpoints.component';

const darkPlaceholder = <div className="dark-placeholder"/>;

class ListingDetailsLoading extends PureComponent {
  render() {
    return (
      <div className="listing-details loading">
        <div className="listingSection-placeHolder">
          <div className="detailSection-placeHolder">
            <div className="flex listing-details-basic listingDetailSection-placeHolder">
              <div className="layout-column">
                <div className="basic-info-container layout-row first">{darkPlaceholder}</div>
                <div className="flex layout-row" style={{ marginTop: '1em' }}>
                  <div className="flex basic-info-container layout-column border-right">
                    {darkPlaceholder}
                  </div>
                  <div className="flex basic-info-container layout-column border-right">
                    {darkPlaceholder}
                  </div>
                  <div className="flex basic-info-container layout-column">
                    {darkPlaceholder}
                  </div>
                  <div className="flex basic-info-container layout-column">
                    {darkPlaceholder}
                  </div>
                </div>
              </div>
              <div className="listingSection">
                <div className="listing-details-size-container layout-row">
                  <div className="listing-details-information-container flex">
                    <div className="listing-details-information">
                      <div className="information-panel">
                        <div className="panel-header">{darkPlaceholder}</div>
                        <div className="description-placeholder panel-content">
                          <div className="first">{darkPlaceholder}</div>
                          {darkPlaceholder}
                          {darkPlaceholder}
                          <div className="third">
                            {darkPlaceholder}
                          </div>
                          <div className="flex layout-row">
                            <div className="flex basic-info-container layout-column border-right">
                              {darkPlaceholder}
                            </div>
                            <div className="flex basic-info-container layout-column border-right">
                              {darkPlaceholder}
                            </div>
                            <div className="flex basic-info-container layout-column">
                              {darkPlaceholder}
                            </div>
                            <div className="flex basic-info-container layout-column">
                              {darkPlaceholder}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="information-panel">
                        <div className="panel-header">{darkPlaceholder}</div>
                        <div className="description-placeholder panel-content" style={{ marginTop: '3em' }}>
                          {darkPlaceholder}
                          {darkPlaceholder}
                          <div className="third">
                            {darkPlaceholder}
                          </div>
                          <div className="flex layout-row">
                            <div className="flex basic-info-container layout-column border-right">
                              {darkPlaceholder}
                            </div>
                            <div className="flex basic-info-container layout-column border-right">
                              {darkPlaceholder}
                            </div>
                            <div className="flex basic-info-container layout-column">
                              {darkPlaceholder}
                            </div>
                            <div className="flex basic-info-container layout-column">
                              {darkPlaceholder}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="information-panel">
                        <div className="panel-header">{darkPlaceholder}</div>
                        <div className="panel-header2" style={{ marginTop: '1.5em' }}>{darkPlaceholder}</div>
                        <div className="panel-content map-placeholder">{darkPlaceholder}</div>
                        <div className="neighborHood">
                          <div>{darkPlaceholder}</div>
                          <div style={{ marginTop: '0.5em' }}>{darkPlaceholder}</div>
                        </div>
                        <div className="panel-header" style={{ marginTop: '1.5em' }}>{darkPlaceholder}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="userDetailSection-placeHolder">
              <div className="listing-details-profile-container">
                <div className="listing-details-profile flex-center layout-column">
                  <div className="user-avatar-container flex-center">
                    {darkPlaceholder}
                  </div>
                  <div className="listing-details-profile-description">
                    <div className="listing-details-profile-pref flex-center layout-column">
                      {darkPlaceholder}
                      {darkPlaceholder}
                    </div>
                  </div>
                  <div className="listing-details-profile-description">
                    <div className="listing-details-profile-pref flex-center layout-column">
                      {darkPlaceholder}
                    </div>
                  </div>
                  <div className="listing-details-profile-information">
                    {darkPlaceholder}
                    {darkPlaceholder}
                    {darkPlaceholder}
                    {darkPlaceholder}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withBreakPoints(ListingDetailsLoading);
