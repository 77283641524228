export default [
  {
    'width': 600,
    'size': 'xs'
  },
  {
    'width': 960,
    'size': 'sm'
  },
  {
    'width': 1280,
    'size': 'md'
  },
  {
    'width': 1920,
    'size': 'lg'
  }
];
