import firebase from 'firebase/app'
import 'firebase/messaging'
import 'firebase/analytics'

const {
  FIREBASE_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MEASURMENT_ID
} = process.env

export const initializeFirebase = () => {
  firebase.initializeApp({
    messagingSenderId: FIREBASE_SENDER_ID,
    projectId: FIREBASE_PROJECT_ID,
    apiKey: FIREBASE_API_KEY,
    appId: FIREBASE_APP_ID,
    authDomain: FIREBASE_AUTH_DOMAIN,
    databaseURL: FIREBASE_DATABASE_URL,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    measurementId: FIREBASE_MEASURMENT_ID
  })

  firebase.analytics()

  // if ('serviceWorker' in navigator) {
  //   const filepath = '/firebase-messaging-sw.js'

  //   navigator.serviceWorker
  //     .register(filepath)
  //     .then(registration => {
  //       firebase.messaging().useServiceWorker(registration)
  //     })
  //     .catch(err =>
  //       console.error('Failed to register service worker', err.message)
  //     )
  // }
}
function getToken() {
  const messaging = firebase.messaging()
  messaging
    .getToken()
    .then(currentToken => {
      if (currentToken) {
        window.localStorage.setItem('firebaseToken', currentToken)
      } else {
        console.log(
          'No registration token available. Request permission to generate one.'
        )
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err)
    })
}

function requestPermission() {
  Notification.requestPermission().then(permission => {
    if (permission === 'granted') {
      console.log('Notification permission granted.')
    } else {
      console.log('Unable to get permission to notify.')
    }
  })
}

export const askForPermissionToReceiveNotifications = async () => {
  try {
    requestPermission()
    getToken()
    return
  } catch (error) {
    console.error(error)
  }
}
