import 'babel-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import InitialLoader from './app/main/initial-loader/initial-loader'

import './index.scss'
import 'mapbox-gl/dist/mapbox-gl.css'
import { RouterToUrlQuery } from 'react-url-query'
import ScrollToTop from './app/shared/components/scroll-to/scroll-to-top.component'
import { Router } from 'react-router'
import history from './app/history'
import {
  askForPermissionToReceiveNotifications,
  initializeFirebase
} from './push-notification'
// import { unregister } from './serviceWorker'

initializeFirebase()
askForPermissionToReceiveNotifications()

const render = Component => {
  ReactDOM.render(
    <Router history={history}>
      <RouterToUrlQuery>
        <ScrollToTop>
          <Component />
        </ScrollToTop>
      </RouterToUrlQuery>
    </Router>,
    document.getElementById('react-root')
  )
}

if (!global.Intl) {
  import([
    'intl',
    'intl/locale-data/jsonp/en.js',
    'intl/locale-data/jsonp/es.js'
  ]).then(() => {
    render(InitialLoader)
  })
} else {
  render(InitialLoader)
}

if (module.hot) {
  module.hot.accept('./app/main/initial-loader/initial-loader', () => {
    const NewApp = require('./app/main/initial-loader/initial-loader').default
    render(NewApp)
  })
}

// unregister()
