import { Grid, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { PureComponent } from 'react';
import userProfileLoadingStyles from './user-profile-loading.component.styles';

@withStyles(userProfileLoadingStyles)
class UserProfileLoading extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.userProfileContainer}>
        <div className={classes.userProfile}>
          <div className={classNames(classes.carouselImage, classes.applyGrey)}/>
          <div className={classNames(classes.userNameSection, classes.applyGrey)}/>
          <div className={classNames(classes.userDetailSection, classes.applyGrey)}/>
          <div className={classNames(classes.userBadgeSection)}>
            <div className={classNames(classes.badge, classes.applyGrey)}/>
            <div className={classNames(classes.badge, classes.applyGrey)}/>
            <div className={classNames(classes.badge, classes.applyGrey)}/>
            <div className={classNames(classes.badge, classes.applyGrey)}/>
            <div className={classNames(classes.badge, classes.applyGrey)}/>
          </div>
        </div>
        <div className={classes.userProfileDetails}>
          <div className={classes.userBio}>
            <div className={classNames(classes.title, classes.applyGrey)}/>
            <Grid container spacing={32}>
              <Grid item xs={12} sm={6}>
                <div className={classNames(classes.title, classes.applyGrey)}/>
                {[1, 2, 3, 4].map((each, index) => <div key={index} className={classNames(classes.description, classes.applyGrey)}/>)}
              </Grid>
              <Grid item xs={12} sm={6}>
                {[1, 2, 3].map((each, index) => <div key={index} className={classNames(classes.title, classes.applyGrey)}/>)}
              </Grid>
            </Grid>
            <Grid container spacing={32}>
              <Grid item xs={12} sm={6}>
                <div className={classNames(classes.title, classes.applyGrey)}/>
                <div className={classes.likesContainer}>
                  {[1, 2, 3, 4, 5, 6].map((each, index) => (
                    <div className={classes.fbLikes} key={index}>
                      <div className={classNames(classes.fbImage, classes.applyGrey)}/>
                      <div>
                        <div className={classNames(classes.fbTitle, classes.applyGrey)}/>
                        <div className={classNames(classes.fbDescription, classes.applyGrey)}/>
                      </div>
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classNames(classes.title, classes.applyGrey)}/>
                {[1, 2, 3].map((each, index) => <div key={index} className={classNames(classes.title, classes.applyGrey)}/>)}
              </Grid>
            </Grid>
          </div>
          <div className={classes.userPreferences}>
            <div className={classNames(classes.userPreferencesTitle, classes.applyGrey)}/>
            {[1, 2].map((each, index) => {
              return (
                <Grid container key={index}>
                  <Grid item xs={12} sm={6}>
                    <div className={classNames(classes.userPreferencesTitle, classes.applyGrey)}/>
                    <div className={classNames(classes.userPreferencesDescription, classes.applyGrey)}/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={classNames(classes.userPreferencesTitle, classes.applyGrey)}/>
                    <div className={classNames(classes.userPreferencesDescription, classes.applyGrey)}/>
                  </Grid>
                </Grid>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfileLoading;
