const userProfileLoadingStyles = theme => {
  return {
    userProfileContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 10}px`,
      [theme.breakpoints.down(1130)]: {
        flexDirection: 'column',
        padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 2}px`
      }
    },
    applyGrey: {
      background: theme.palette.grey[300]
    },
    userProfile: {
      position: 'relative',
      paddingRight: theme.spacing.unit * 2.5,
      width: 400,
      height: 500,
      border: `1px solid ${theme.palette.grey[300]}`,
      [theme.breakpoints.down(1130)]: {
        width: '100%',
        paddingRight: '0'
      }
    },
    carouselImage: {
      height: 235,
      width: 250,
      margin: 'auto',
      marginTop: theme.spacing.unit * 5,
      borderRadius: 8
    },
    userNameSection: {
      height: 30,
      width: '45%',
      margin: 'auto',
      marginTop: theme.spacing.unit * 8
    },
    userDetailSection: {
      height: 20,
      width: '35%',
      margin: 'auto',
      marginTop: theme.spacing.unit * 3
    },
    userBadgeSection: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px'
    },
    badge: {
      height: 40,
      width: 40,
      borderRadius: '50%',
      marginLeft: 5
    },
    userProfileDetails: {
      display: 'flex',
      maxWidth: '100%',
      flexDirection: 'column',
      flex: '1 1 0% !important',
      minWidth: 0,
      minHeight: 0,
      [theme.breakpoints.down(1130)]: {
        padding: 0,
        marginTop: theme.spacing.unit * 2
      }
    },
    userBio: {
      border: `1px solid ${theme.palette.grey[300]}`,
      minHeight: 500,
      marginLeft: theme.spacing.unit * 2.5,
      padding: theme.spacing.unit * 2.5,
      [theme.breakpoints.down(1130)]: {
        marginLeft: 0
      }
    },
    bioHeader: {
      height: 25,
      width: 135,
      marginTop: theme.spacing.unit * 3
    },
    title: {
      height: 17,
      width: 135,
      margin: `${theme.spacing.unit * 3}px 0`
    },
    description: {
      height: 17,
      width: 231,
      marginTop: theme.spacing.unit
    },
    likesContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing.unit - 20
    },
    fbLikes: {
      display: 'flex',
      width: '50%',
      marginTop: theme.spacing.unit * 2,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    fbImage: {
      height: 34,
      width: 34,
      borderRadius: '50%'
    },
    fbTitle: {
      height: 17,
      width: 80,
      marginLeft: theme.spacing.unit * 0.5
    },
    fbDescription: {
      height: 17,
      width: 65,
      marginLeft: theme.spacing.unit * 0.5,
      marginTop: theme.spacing.unit * 0.5
    },
    userPreferences: {
      border: `1px solid ${theme.palette.grey[300]}`,
      marginTop: theme.spacing.unit * 3,
      minHeight: 275,
      marginLeft: theme.spacing.unit * 2.5,
      padding: `${theme.spacing.unit * 2.5}px`,
      [theme.breakpoints.down(1130)]: {
        marginLeft: 0,
        marginTop: theme.spacing.unit * 2
      }
    },
    userPreferencesTitle: {
      height: 17,
      width: 225,
      marginTop: theme.spacing.unit * 3
    },
    userPreferencesDescription: {
      height: 17,
      width: 100,
      marginTop: theme.spacing.unit * 2
    }
  }
}

export default userProfileLoadingStyles
