import { Grid, Hidden } from '@material-ui/core'
import React from 'react'
import ContentLoader from 'react-content-loader'

const renderPeople = i => {
  return (
    <ContentLoader key={i} height={'257'} width={'250'}>
      <rect width="100%" height="180" />
      <rect y="73%" x="12" width="25%" height="16" />
      <circle cy="77%" cx="62%" r="13" />
      <circle cy="77%" cx="75%" r="13" />
      <circle cy="77%" cx="88%" r="13" />
      <rect y="83%" x="5%" width="30%" height="14" />
      <rect y="90%" x="5%" width="40%" height="18" />
    </ContentLoader>
  )
}

const placeHolders = Array(16).fill(1)

const BrowsePeopleLoader = () => {
  return (
    <div>
      <div style={{ padding: 32 }}>
        <ContentLoader height={'32'} width={'200'}>
          <rect x="0" y="0" width="100%" height="100%" />
        </ContentLoader>
        <div
          style={{
            marginTop: 22,
            width: 600,
            maxWidth: '100%'
          }}
        >
          <ContentLoader height={'42'} width={'100%'}>
            <rect x="0" y="0" width="100%" height="100%" />
          </ContentLoader>
        </div>
      </div>
      <div style={{ padding: '32px 72px', background: '#f0f4ff', display: 'flex', alignItems: 'center' }}>
        <Grid
          container
          alignItems={'center'}
          justify={'center'}
          wrap={'wrap'}
          style={{ maxWidth: '1600px' }}
        >
          {placeHolders.map((i, idx) => (
            <div key={idx} style={{ padding: 16 }}>
              <div style={{ background: 'white', borderRadius: 8 }}>
                {renderPeople(i)}
              </div>
            </div>
          ))}
        </Grid>
      </div>
    </div>
  )
}

export default BrowsePeopleLoader
