export function isDadaDomain() {
  return window.location.host.includes('dadaroom.com')
}

export function isEs() {
  return window.location.pathname.includes('/es')
}

export function getDomainName() {
  if (window.location.host.includes('dadaroom.com')) return 'Dada Room'
  return 'Roomi'
}

export function getCompanyName() {
  if (window.location.host.includes('dadaroom.com')) return 'Dada Room by Roomi, Inc.'
  return 'Roomi, Inc.'
}

export function getContactEmail() {
  if (window.location.host.includes('dadaroom.com')) return 'hola@dadaroom.com'
  return 'hello@roomiapp.com'
}

export function getSpamEmailFormat() {
  if (window.location.host.includes('dadaroom.com')) return '@dadaroom.com'
  return '@roomiapp.com'
}

export function getTwitterHandle(country) {
  const latamCountries = ['AR', 'CL', 'CO', 'MX', 'PE', 'BR']
  if (country && latamCountries.includes(country)) return '@RoomiAppLatam'
  return '@RoomiApp'
}
