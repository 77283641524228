import { Grid, Hidden } from '@material-ui/core'
import React from 'react'
import ContentLoader from 'react-content-loader'

const placeholders = [1, 2, 3, 4, 5, 6, 7]

const searchStyles = {
  listingCard: {
    position: 'relative',
    width: '100%',
    paddingBottom: '90%'
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
}

const renderListing = () => {
  return (
    <div style={searchStyles.absolute}>
      <ContentLoader height={'100%'} width={'100%'}>
        <rect x="0" y="0" width="100%" height="70%" />
        <rect y="74%" x="12" width="25%" height="8" />
        <rect y="80%" x="12" width="53%" height="12" />
        <rect y="87%" x="12" width="75%" height="16" />
      </ContentLoader>
    </div>
  )
}

const SearchLoader = () => {
  return (
    <Grid container style={{ flex: 1, flexShrink: 0, position: 'relative' }}>
      <div
        style={{ ...searchStyles.absolute, display: 'flex', overflow: 'auto' }}
      >
        <Grid
          item
          md={7}
          xs={12}
          style={{ padding: '24px 12px', overflow: 'auto' }}
        >
          <Grid container wrap={'wrap'}>
            {placeholders.map(i => (
              <Grid key={i} item xs={12} sm={6} lg={4} style={{ padding: 12 }}>
                <div style={searchStyles.listingCard}>{renderListing()}</div>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Hidden mdDown>
          <div
            style={{
              width: (5 / 12) * 100 + '%',
              position: 'relative',
              background: '#DEDEDE'
            }}
          />
        </Hidden>
      </div>
    </Grid>
  )
}

export default SearchLoader
