import React, { PureComponent } from 'react';
import {
  registerSizeChangeHandler,
  deRegisterSizeChangeHandler,
} from '../../utils/window-info';

export default function(ComposedComponent) {
  class WithBreakPoints extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {};
      this.handleSizeChange = this.handleSizeChange.bind(this);
    }

    handleSizeChange({ currentSize: size }) {
      this.setState({ size });
    }

    componentDidMount() {
      registerSizeChangeHandler(this.handleSizeChange);
    }

    componentWillUnmount() {
      deRegisterSizeChangeHandler(this.handleSizeChange);
    }

    render() {
      return <ComposedComponent size={this.state.size} {...this.props} />;
    }
  }

  return WithBreakPoints;
}
