import { remove, each, find, get } from 'lodash'
import BrowserInfo from './browser-info/browser.info'
import breakPoints from '../data/breakpoints'

const browserInfo = new BrowserInfo()
const sizeHandlers = []
const virtualKeyboardHandlers = []
const onCloseHandlers = []

const windowInfo = {
  ...browserInfo.giveMeAllYouGot(),
  currentSize: getSize(),
  isVirtualKeyBoardActive: false,
  isTouchDevice: 'ontouchstart' in window || window.navigator.msMaxTouchPoints,
  focused: true
}

window.onblur = () => {
  windowInfo.focused = false
}
window.onfocus = () => {
  windowInfo.focused = true
}
window.addEventListener('resize', runHandlers)
window.addEventListener('orientationchange', runHandlers)
window.onbeforeunload = () => {
  each(onCloseHandlers, handler => handler())
}

function runHandlers() {
  const newKeyBoardDetection = isVirtualKeyBoardActive()
  if (newKeyBoardDetection !== windowInfo.isVirtualKeyBoardActive) {
    windowInfo.isVirtualKeyBoardActive = newKeyBoardDetection
    each(virtualKeyboardHandlers, handler => {
      handler && handler(windowInfo)
    })
  }
  const size = getSize()
  if (size !== windowInfo.currentSize) {
    windowInfo.currentSize = size
    each(sizeHandlers, handler => {
      handler && handler(windowInfo)
    })
  }
}

function isVirtualKeyBoardActive() {
  return (
    windowInfo.isMobile &&
    get(window.screen, 'orientation.angle') === 0 &&
    window.screen.availHeight * 0.7 > window.innerHeight
  )
}

function getSize() {
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  const width = iOS ? window.screen.width : window.innerWidth
  const winSize = find(breakPoints, breakpoint => width < breakpoint.width) || {
    size: 'xl'
  }
  return winSize.size
}

export function registerSizeChangeHandler(handler) {
  if (!handler) return
  handler(windowInfo)
  sizeHandlers.push(handler)
}

export function registerVirtualKeyboardHandler(handler) {
  if (!handler) return
  handler(windowInfo)
  virtualKeyboardHandlers.push(handler)
}

export function deRegisterVirtualKeyboardHandler(handler) {
  remove(virtualKeyboardHandlers, hand => hand === handler)
}

export function deRegisterSizeChangeHandler(handler) {
  remove(sizeHandlers, hand => hand === handler)
}

export function onWindowClose(fn) {
  onCloseHandlers.push(fn)
}

export default windowInfo
